<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>
  </div>
</template>

<script>
  import { MessageError } from "@/util/util"
  export default {
    name: "user",
    data(){
      const columns = [
        // {type: 'selection'},
        {prop: 'id',label: '主键id'},
        {prop: 'name',label: '姓名'},
        {prop: 'email',label: '邮箱'},
        {prop: 'ip',label: 'IP地址'},
        {prop: 'address',label: '地址'},
        {prop: 'createTime',label: '创建时间'},
      ]
      return {
        columns,
        tableConfig: {
          url: 'loginlog/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [],
          headerButtons: [],
          hasDelete: false,
          hasEdit: false,
          hasOperation: false,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
      }
    },
    methods:{
     getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
